var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-5 rounded-lg overflow-hidden"},[_c('div',{staticClass:"h-48 flex flex-col gap-4 items-center justify-center",class:{
      'bg-white': !_vm.isModal,
      'bg-gray-100': _vm.isModal
    }},[_c('AppLogo'),_vm._m(0)],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),(_vm.isModal)?_c('div',{staticClass:"px-5 pb-5"},[_c('fw-button',{attrs:{"type":"light","expanded":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")])],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-3 text-lg font-bold opacity-70"},[_c('div',[_vm._v("Translate")]),_c('div',[_vm._v("Review")]),_c('div',[_vm._v("Share")]),_c('div',[_vm._v("Disseminate")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"text-xl font-bold mb-2"},[_vm._v("About Mondaecus")]),_vm._v(" Mondaecus aims to eliminate linguistic obstacles in a diverse scholarly communication context and to enhance multilingualism as an asset, rather than a limitation, for the advancement of science and the transfer of knowledge. By means of collaborative translation, Mondaecus introduces a human dimension to machine translation and sensitivity to different linguistic contexts. The platform provides a multilingual online network for the exchange of ideas across disciplines, thereby fostering collaboration and communication. Mondaecus aims to eliminate linguistic obstacles in a diverse scholarly communication context and to enhance multilingualism as an asset, rather than a limitation, for the advancement of science and the transfer of knowledge. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"text-xl font-bold mb-2"},[_vm._v("Vision")]),_c('ul',{staticClass:"list-disc list-inside"},[_c('li',[_vm._v("Openness to all communities during the learning stages")]),_c('li',[_vm._v("Epistemological discussion and assessment not limited by linguistic barriers")]),_c('li',[_vm._v("Full access to knowledge produced in diverse linguistic contexts")]),_c('li',[_vm._v("Promotion of equality and diversity")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"text-xl font-bold mb-2"},[_vm._v("Available features (alpha version)")]),_c('ul',{staticClass:"list-disc list-inside"},[_c('li',[_vm._v("Communities based Experience")]),_c('li',[_vm._v("Document Components Editor")]),_c('li',[_vm._v("AI Helper Translation Service")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-5"},[_vm._v(" Should you wish to make any suggestions for improvements or report any bugs, please contact: "),_c('a',{staticClass:"text-primary hover:opacity-60",attrs:{"href":"mailto:operas-pt@uc.pt"}},[_vm._v("operas-pt@uc.pt")])])
}]

export { render, staticRenderFns }