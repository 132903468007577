<template>
  <fw-layout
    :full="view == 'files'"
    :show-right-sidebar="showChat"
    :right-sidebar-width="showChat ? 'w-1/2' : null"
    :connected-users="connectedUsers"
    subheader
    @back="backTo"
  >
    <template #subheader>
      <div v-if="loading || community == null" class="h-12 flex w-full gap-3 pl-5 pr-2 py-2">
        <div class="rounded-md w-64 bg-gray-200 animate-pulse"></div>
        <div class="rounded-md w-28 bg-gray-200 animate-pulse"></div>
        <div class="rounded-md w-28 bg-gray-200 animate-pulse"></div>
        <div class="rounded-md w-28 bg-gray-200 animate-pulse"></div>
        <div class="flex-1"></div>
        <div class="rounded-md w-10 bg-gray-200 animate-pulse"></div>
      </div>
      <div v-else class="h-12 flex w-full overflow-x-auto">
        <fw-heading class="flex-shrink-0 ml-5 mr-3 leading-10 py-1 max-w-lg overflow-ellipsis line-clamp-1" size="lg">{{
          community.title
        }}</fw-heading>
        <div v-if="isOwner" class="flex items-center justify-center mr-3">
          <div class="management-badge" @click="openSettings()">
            <fw-icon-settings class="w-4 h-4 md:w-5 md:h-5" />
          </div>
        </div>
        <fw-button
          v-if="isMember"
          :type="view == 'summary' ? 'tab-active' : 'tab'"
          class="px-5"
          @click.native="openView(null)"
          >{{ $t('start') }}</fw-button
        >
        <fw-button
          v-if="isMember"
          :type="view == 'files' ? 'tab-active' : 'tab'"
          class="px-5"
          @click.native="openView('files')"
          >{{ $t('files') }}</fw-button
        >
        <fw-button
          v-if="isMember"
          :type="view == 'members' ? 'tab-active' : 'tab'"
          class="px-5"
          @click.native="openView('members')"
        >
          {{ $t('members') }}
          <div
            v-if="community.stats.users.pending > 0 && isOwner"
            class="block-inline bg-primary w-5 h-5 rounded-full text-white leading-5 ml-2"
          >
            {{ community.stats.users.pending }}
          </div>
        </fw-button>
        <div class="flex-1"></div>
        <div class="p-1.5 flex gap-2">
          <BlockHeaderDebug>
            <fw-panel-info label="User permissions (raw)" debug>
              <json-viewer
                :value="{
                  community
                }"
              ></json-viewer>
            </fw-panel-info>
          </BlockHeaderDebug>
        </div>
      </div>
    </template>
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>
    <template #main-content>
      <panel-community-summary
        v-if="view === 'summary' && community"
        :community="community"
        :users="users"
        @reload="loadCommunity()"
      />
      <panel-community-files v-else-if="view === 'files' && community" :community="community" :users="users" />
      <panel-community-members
        v-else-if="view === 'members' && community"
        :community="community"
        :community-users="users"
        :connected-users="onlineUsers"
        @reload="loadCommunity"
      />
    </template>
    <template #right-sidebar>
      <PanelChats
        v-if="showChat"
        :meeting="community.meeting"
        :chat-meeting-only="true"
        minimal
        :show-uploader="false"
        :chats="chats"
        :chat-users="chatUsers"
        :all-chat-users="allChatUsers"
        :chat-active="chatActive"
        :editable="true"
        :view-chats-list="false"
        :allow-downloads="true"
        :load-chat-on-create="true"
        @load-chat="loadChat"
        @unload-chat="unloadChat"
        @set-chat-messages="setChatMessages"
        @delete-chat-message="deleteChatMessage"
      />
      <!--
      @load-main-chat="loadMainChat"
      @unload-chat="unloadChat"

      @delete-chat-message="deleteChatMessage" -->
    </template>
    <template #modals>
      <fw-modal
        v-if="showEditCommunityModal"
        :active.sync="showEditCommunityModal"
        :can-cancel="true"
        size="min"
        paddingless
        @close="close"
      >
        <template #default>
          <ModalEditCommunity :community="community" @close="close" @reload="loadCommunity" />
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import ServiceContent from '@/fw-modules/fw-core-vue/content/services/ServiceContent'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'
import FwHeading from '../fw-modules/fw-core-vue/ui/components/text/FwHeading.vue'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
import PanelCommunitySummary from '../fw-modules/fw-core-vue/content/components/panels/PanelCommunitySummary.vue'
import PanelCommunityFiles from '../fw-modules/fw-core-vue/content/components/panels/PanelCommunityFiles.vue'
import PanelCommunityMembers from '../fw-modules/fw-core-vue/content/components/panels/PanelCommunityMembers.vue'
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'
import BlockHeaderDebug from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderDebug'
import ModalEditCommunity from '@/fw-modules/fw-core-vue/content/components/modals/ModalEditCommunity'
export default {
  components: {
    HeaderLanguagePanel,
    FwHeading,
    PanelCommunitySummary,
    PanelCommunityFiles,
    PanelCommunityMembers,
    PanelChats,
    BlockHeaderDebug,
    ModalEditCommunity
  },
  mixins: [ChatLive],
  data() {
    return {
      loading: true,
      community: null,
      users: {},
      rightSidebar: 'chat',
      creating: {
        meetings: false,
        chat: false
      },
      destroying: false,
      showEditCommunityModal: false,
      onlineUsers: {},
      realtimeDebouncer: null
    }
  },
  computed: {
    wsMessages() {
      return this.$store.state.session.unreadExamWsMessages
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    connectedUsers() {
      return Object.keys(this.onlineUsers).length > 0 ? Object.values(this.onlineUsers) : []
    },
    showChat() {
      return this.community && this.community.chat && this.isMember && this.view == 'summary'
    },
    view() {
      return this.$route.params.view || 'summary'
    },
    isOwner() {
      return this.community && this.community.validations.is_owner
    },
    isMember() {
      return this.community && (this.community.validations.is_member || this.community.validations.is_owner)
    }
  },
  watch: {
    wsMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages && newMessages.length > 0) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('communityWSMessages changed', newMessages)
          //change user state
          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if (message.type == 'groupOnlineUsers' && message.data.length > 0) {
              let allGroupsUsers = message.data
              //find this community users
              let currentCommunityUsers = allGroupsUsers.filter(group => group.group_key == this.community.key)
              if (currentCommunityUsers && currentCommunityUsers.length > 0) {
                console.log('currentCommunityUsers', currentCommunityUsers[0].users)
                this.onlineUsers = currentCommunityUsers[0].users
              }
            }
          }
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          this.realtimeDebouncer = null
        }, 2000)
      }
    },
    socketId() {
      this.subscribe()
    },
    community() {
      this.subscribe()
    }
  },
  created() {
    this.loadCommunity()
    this.subscribe()
  },
  beforeDestroy() {
    this.destroying = true
    this.unsubscribe()
    if (this.community.chat) {
      this.unregisterChat()
    }
  },
  methods: {
    async subscribe() {
      if (this.socketId && this.community) {
        try {
          let resultWs = await ServiceContent.subscribeCommunity(
            this.socketId,
            'subscribe_community',
            this.community.key
          )
          console.log('subscribe', resultWs)
        } catch (e) {
          console.error(e)
        }
      }
    },
    async unsubscribe() {
      if (this.socketId && this.community) {
        try {
          let resultWs = await ServiceContent.subscribeCommunity(
            this.socketId,
            'unsubscribe_community',
            this.community.key
          )
          console.log('subscribe', resultWs)
        } catch (e) {
          console.error(e)
        }
      }
    },
    close() {
      this.showEditCommunityModal = false
    },
    openSettings() {
      this.showEditCommunityModal = true
    },
    isInChatView() {
      return !this.destroying
    },
    toogleRightside(what) {
      this.rightSidebar = this.rightSidebar === what ? null : what
    },
    openView(view) {
      this.$router.push({ name: 'community', params: { key: this.$route.params.key, view: view } })
    },
    openChat() {
      if (this.community.chat && this.community.chat.key) {
        this.toogleRightside('chat')
      }
    },
    openMeeting() {
      if (this.community.meeting && this.community.meeting.key_alias) {
        this.$router.push({ name: 'live', params: { key: this.community.meeting.key_alias } })
      }
    },
    async loadCommunity() {
      this.loading = true
      try {
        let response = await ServiceContent.getGroup(this.$route.params.key)
        console.log('loadCommunity', response.data)
        this.community = response.data.group
        if (this.community.chat) {
          this.registerChat()
          //loadChat(chatOrUser, quietly = false, force = false, callback = null
          this.loadChat(this.community.chat, true, true, null)
        }
        this.users = { ...this.users, ...response.data.users }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    backTo() {
      this.$router.go(-1)
    }
  }
}
</script>

<i18n>
    {
      "en": {
        "communities": "Communities",
        "new_community": "New community",
        "experimental_warning1": "The Communities feature is in",
        "experimental_warning2": "testing phase",
        "experimental_warning3": ". If you encounter any errors or identify aspects that can be improved, please contact us at",
        "no_results": "No communities to show",
        "start": "Dashboard",
        "files": "Files",
        "members": "Members"
      },
      "pt": {
        "communities": "Comunidades",
        "new_community": "Nova comunidade",
        "experimental_warning1": "A funcionalidade de Comunidades está em",
        "experimental_warning2": "fase de testes",
        "experimental_warning3": ". Caso se depare com algum erro ou identifique aspetos passíveis",
        "no_results": "Não existem comunidades para mostrar",
        "start": "Início",
        "files": "Ficheiros",
        "members": "Membros"
      }
    }
  </i18n>

<style scoped>
.management-badge {
  @apply text-xs font-bold rounded-lg p-0.5 -mt-0.5 flex items-center justify-center hover:opacity-70 cursor-pointer;
  color: #323232;
  border: 2px solid #323232;
}
</style>
